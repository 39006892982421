@import 'styles/includes.scss';

.Hero {
    position: relative;
    padding-top: 136px;
    overflow: hidden;

    h1 {
        @include start-headline;
        max-width: $max-hero-text;
    }

    p {
        @include preamble;
        max-width: $max-hero-text;
        margin: 48px 0 40px;
    }

    em {
        color: $color-weird-green;
    }
}

.Container {
    @extend %wrap;
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-flow: column;
    min-height: 425px;
    padding-bottom: 60px;

    @include media(l) {
        min-height: 568px;
    }
}
