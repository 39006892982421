@import 'styles/includes.scss';

.Root {
    @include media(m) {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: -150px;
            top: 850px;
            width: 500px;
            height: 500px;
            background: url(#{$basepath}img/svg/icon-green-1.svg) no-repeat
                center center;
            background-size: contain;
            z-index: $z-behind;
        }

        &::after {
            content: '';
            position: absolute;
            right: -400px;
            top: 35%;
            width: 700px;
            height: 700px;
            background: url(#{$basepath}img/svg/icon-purple.svg) no-repeat
                center center;
            background-size: contain;
            z-index: $z-behind;
        }
    }
}

.Wrap {
    @extend %wrap;
    position: relative;
}

.Billboard {
    margin-bottom: 40px;
    @extend %wrap;
    display: flex;
    justify-content: flex-end;
}

.OfferWrapper {
    padding-left: 18px;

    margin-bottom: 72px;

    @include media(ml) {
        margin-bottom: 0;
    }
}

.OfferItem {
    margin-bottom: 40px;
    max-width: 328px;

    &:last-child {
        margin-bottom: 0;
    }
}

.ProjectWrapper {
    display: flex;
    flex-direction: column;
}

.ProjectRow1 {
    @include media(ml) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.ProjectRow2 {
    @include media(ml) {
        display: flex;
        flex-direction: row;
    }
}

.ProjectRow3 {
    @include media(ml) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}

.ProjectRowFiller {
    @include media(ml) {
        width: 488px;
        flex-shrink: 0;
    }
}

.ProjectItem1 {
    margin-bottom: 24px;

    @include media(ml) {
        max-width: 830px;
        height: 534px;
        margin-bottom: 72px;
        align-self: flex-end;
        margin-top: -72px;
    }
}

.ProjectItem2 {
    margin-bottom: 24px;

    @include media(ml) {
        max-width: 830px;
        height: 534px;
        margin-bottom: 80px;
    }
}

.ProjectItem3 {
    @include media(ml) {
        margin-top: -264px;
        max-width: 416px;
        align-self: flex-end;
        height: 534px;
    }

    @include media(xxl) {
        max-width: 488px;
        align-self: flex-end;
    }
}

.ProjectsAll {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    @include media(ml) {
        margin-top: 48px;
    }
}

.ContactWrapper {
    display: inline-block;

    margin-top: 64px;
    margin-bottom: 56px;

    @include media(ml) {
        margin-top: -208px;
        margin-bottom: 140px;
    }

    @include media(xl) {
        padding-left: 16px;
    }

    @include media(xxl) {
        padding-left: 104px;
    }
}

.Videos {
    margin-bottom: 80px;

    @include media(m) {
        margin-bottom: 120px;
    }

    @include media(l) {
        padding-left: 120px;
    }
}

.ArticlesWrapper {
    margin-top: 16px;

    @include media(m) {
        margin-top: 24px;
    }

    @include media(xl) {
        margin-top: 56px;
    }
}
