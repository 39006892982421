@import 'styles/includes.scss';

.Root {
    text-align: center;
    margin-bottom: 116px;
    @include media(m) {
        margin-bottom: 58px;
    }
}

.Heading {
    @include h1-headline;
    max-width: $max-heading;
    margin: 0 auto 24px;

    @include media(m) {
        margin-bottom: 40px;
    }
}

.Balls {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;

    @include media(sm) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media(m) {
        grid-template-columns: repeat(5, 1fr);
    }

    @include media(l) {
        grid-template-columns: repeat(5, 240px);
        grid-template-rows: repeat(3, 240px);
    }
}

.Ball {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    background: $color-light-pink;
    opacity: 0;

    &--AnimateTop {
        animation-name: fadeInFromTop;
        animation-duration: 400ms;
        animation-timing-function: ease-out;
        animation-delay: 350ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &--AnimateBottom {
        animation-name: fadeInFromBottom;
        animation-duration: 400ms;
        animation-timing-function: ease-out;
        animation-delay: 350ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    @include media(l) {
        padding-bottom: 0;
    }

    @include media(m) {
        &:nth-child(1) {
            grid-area: 2 / 1 / 3 / 2;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(2) {
            grid-area: 2 / 2 / 3 / 3;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(3) {
            grid-area: 3 / 2 / 4 / 3;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(4) {
            grid-area: 1 / 3 / 2 / 4;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(5) {
            grid-area: 2 / 3 / 3 / 4;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(6) {
            grid-area: 3 / 3 / 4 / 4;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(7) {
            grid-area: 2 / 4 / 3 / 5;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(8) {
            grid-area: 3 / 4 / 4 / 5;
            animation-delay: (random(10) * 100) + ms;
        }
        &:nth-child(9) {
            grid-area: 2 / 5 / 3 / 6;
            animation-delay: (random(10) * 100) + ms;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(7),
        &:nth-child(8) {
            transform: translateY(-50%);
        }
    }
}

.BallGreen {
    background-color: $color-weird-green;
}

.BallPink {
    background-color: $color-light-pink;
}

.BallIce {
    background-color: $color-ice;
}

.BallLavender {
    background-color: $color-lavender;
}

.Content {
    position: absolute;
    padding: 8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.Title {
    @include h2-headline;
    margin-bottom: 8px;
}

.Text {
    @include small-labels;
}

.Logo {
    max-width: 40px;
    max-height: 16px;
    margin-top: 8px;

    @include media(m) {
        max-width: 80px;
        max-height: 32px;
        margin-top: 16px;
    }
}
