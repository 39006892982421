@import "styles/includes.scss";

.Root {
    min-height: 450px;
    margin-bottom: 100px;
}

.Wrap {
    display: flex;
    flex-wrap: wrap;

    @include media(l) {
        flex-wrap: nowrap;
    }

    @media (min-width: 1920px) {
        padding-left: $wrap-gutter-xxl;
        padding-right: $wrap-gutter-xxl;
        max-width: $bp-xxxl;
        margin: 0 auto;
    }
}

.ImageContainer {
    padding-top: 56.25%;
    width: 100%;
    position: relative;

    @include media(l) {
        width: 45%;
        padding-top: 0;
    }
}

.Image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Logo {
    width: 140px;
    height: auto;
}

.Content {
    background-color: #fff;
    @include media(l) {
        width: 55%;
    }
    padding: 50px;
}

.Title {
    @include h3-headline;

    em {
        color: $color-weird-green;
    }
}

.Text {
    @include body-copy;
    margin-bottom: 40px;

    @include media(l) {
        padding-right: 10%;
    }

    @media (min-width: 1920px) {
        padding-right: 200px;
    }
}
