@import 'styles/includes.scss';

.Root {
    padding-left: 72px;
    background-position: left center;
    background-size: 56px 56px;
    background-repeat: no-repeat;
    min-height: 56px;

    @include media(m) {
        padding-left: 104px;
        background-size: 80px 80px;
        min-height: 80px;
    }
}

.Link {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

.Title {
    @include h4-headline;
    color: $color-black;
}

.Text {
    @include body-copy-small;
}
